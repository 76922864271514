import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import axios from "axios"

import { Typography, Container, makeStyles } from "@material-ui/core"

import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import Breadcrumbs from "../components/breadcrumbs"

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document

const useStyles = makeStyles(theme => ({
  mainContainer: {
    marginTop: "3.5rem",
    marginBottom: "3.5rem",
  },
}))

const AboutUs = ({ pageContext }) => {
  const [t, i18n] = useTranslation()
  const classes = useStyles()
  const { pLang, data } = pageContext

  // const [aboutUsData, setAboutUsData] = useState()

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(pLang, `/${i18n.language}/about-us`)
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(pLang, `about-us`, `/${i18n.language}/about-us`)
      } else {
        documentGlobal.location.href = `/${i18n.language}/about-us`
      }
    })
  }, [i18n, pLang])

  const breadcrumbs = [
    {
      text: t("header.aboutUsPage"),
    },
  ]

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.GATSBY_API_URL}/options/about_us`, {
  //       headers: { "Accept-Language": i18n.language },
  //     })
  //     .then(res => {
  //       setAboutUsData(res.data.about_us)
  //     })
  // }, [i18n])

  return (
    <>
      <SEO title={t("pageTitles.aboutUs")} lang={i18n.language} />
      <Breadcrumbs links={breadcrumbs} />
      <PageTitle title={t("pageTitles.aboutUs")} />
      <Container className={classes.mainContainer}>
        <Typography variant="h6" component="h2">
          <div dangerouslySetInnerHTML={{ __html: data }} />
        </Typography>
      </Container>
    </>
  )
}
export default AboutUs
